"use strict";

import loadScript from '@elements/load-script'

export function initInScope($scope) {
    loadScript("https://api.qparks.com/action/minify/cust/cms/file/funslope-js/widget/funslope-stanton/").done(function () {

        let setupWidget = new ymWidgets();

        if (_config.lang && _config.lang !== 'de') {
            setupWidget.funslope(
                $scope.find("[data-ym=widget]"),
                "/setup/funslope-stanton-en/"
            );
        } else {
            setupWidget.funslope(
                $scope.find("[data-ym=widget]"),
                "/setup/funslope-stanton/"
            );
        }
    });
}