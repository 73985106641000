"use strict";

const selectors = {
    newsletterPopupModal: '.js-newsletter-popup__modal',
    newsletterPopupClose: '.js-newsletter-popup__close',
    newsletterFormSubmit: '.js-newsletter-popup__submit',
    newsletterMobileForm: '.js-newsletter-popup__mobile-form',
    newsletterForm: '.js-newsletter-popup__form',
    newsletterPopupBackdrop: '.js-newsletter-popup__backdrop',
    newsletterInitiallyOpenInput: '.js-newsletter-popup__mobile-initially-open'
}

const classes = {
    showPopup: 'show',
    initialTransitionBlocker: 'initial-transition-blocker',
    modalOpen: 'modal-open'
}

const APPEAR_AGAIN_AFTER_DISMISSAL = 4 * 24 * 60 * 60 * 1000; // 4 days
const SHOW_AFTER_SESSION_START = 30000;
const MIN_SHOW_TIME = 5000;

export function initInScope ($scope) {
    $scope.find(selectors.newsletterPopupModal).each(function(element) {
        $(this).removeClass(classes.initialTransitionBlocker);
        const mobileForm = $(this).find(selectors.newsletterMobileForm);

        $(this).find(selectors.newsletterPopupClose).on("click", function(){
            $(this).closest(selectors.newsletterPopupModal).removeClass(classes.showPopup);
            $('body').removeClass(classes.modalOpen);
            handleDismissal();
        });

        $(this).find(selectors.newsletterPopupBackdrop).on("click", function(){
            $(this).closest(selectors.newsletterPopupModal).removeClass(classes.showPopup);
            $('body').removeClass(classes.modalOpen);
            handleDismissal();
        });

        $(this).find(selectors.newsletterFormSubmit).on("click", function(event){
            if(isMobile()) {
                event.preventDefault();

                if($(this).closest(selectors.newsletterForm).find(selectors.newsletterInitiallyOpenInput).length > 0) {
                    mobileForm.find(selectors.newsletterInitiallyOpenInput).val("true");
                } else {
                    mobileForm.find(selectors.newsletterInitiallyOpenInput).val("");
                }

                const submitAction = $(this).data("submit-action");
                mobileForm.attr("data-action", submitAction);
                mobileForm.attr("action", submitAction);
                mobileForm.submit();
            }

            handleDismissal();
        });

        if(getWithExpiry("newsletter-popup_last-dismissal") === null) { // check if the last dismissal is more than the specified time ago
            // keep track of the session to calculate the timeDifferenceFromSessionStart properly
            if(getWithExpiry("newsletter-popup_session-start") === null) {
                setWithExpiry({
                    key: "newsletter-popup_session-start",
                    value: new Date().toISOString(),
                    ttl: APPEAR_AGAIN_AFTER_DISMISSAL
                });
            }

            const timeDifferenceFromSessionStart = Date.now() - new Date(getWithExpiry("newsletter-popup_session-start")).getTime();
            const delayUntilShow = Math.max(SHOW_AFTER_SESSION_START - timeDifferenceFromSessionStart, MIN_SHOW_TIME);

            setTimeout(() => {
                $(this).addClass(classes.showPopup);
                isMobile() && $('body').addClass(classes.modalOpen);

            }, delayUntilShow);
        }
    });
}

function handleDismissal() {
    setWithExpiry({
        key: "newsletter-popup_last-dismissal",
        value: new Date().toISOString(),
        ttl: APPEAR_AGAIN_AFTER_DISMISSAL
    });

    localStorage.removeItem("newsletter-popup_session-start");
}

function setWithExpiry({key, value, ttl}) {
    const now = new Date();

    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

function isMobile() {
    return window.matchMedia('(max-width: 767px)').matches;
}