"use strict";

export function initInScope ($scope) {
    try{
        if(!window.sessionStorage.getItem('warningHidden')) {
            $scope.find('#warningModal').modal('show');
        }

        $scope.find('#warningModal').on('hidden.bs.modal', function (e) {
            window.sessionStorage.setItem('warningHidden', true);
        });

    }catch(e){
        console.log(e);
    }
}
