"use strict";

export function initInScope ($scope) {
    $('body').on('click', '.vjs-big-play-button', function (evt) {
        let $video = $(this).closest('div').find('video');
        let videoSrc = $video.attr('src');
        let VideoName = videoSrc.substring(videoSrc.lastIndexOf('/')+1);

        if (window['dataLayer'] && typeof window.dataLayer.push === 'function') {
            window.dataLayer.push({
                event: 'cookie-permission-change'
            })
            dataLayer.push({
                'event': 'clickPlay',
                'fileName': VideoName
            });
        }
    });
}