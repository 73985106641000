"use strict";

export function initInScope ($scope) {
    if (matchMedia('(hover: none) and (pointer:coarse)').matches) {
        return;
    }

    let $cursor = $scope.find('.js-custom-cursor');

    document.addEventListener("mousemove", e => {
        $cursor.css('transform', `translate(${e.clientX}px, ${e.clientY}px)`);
    });

    document.addEventListener('mousedown', e => {
        $cursor.addClass('is-active')
    });
    document.addEventListener('mouseup', e => {
        $cursor.removeClass('is-active')
    });

    let interactiveElements = ['input', 'label', 'a', 'button', 'select', 'textarea', 'button']
        .map(x => x + ':not([disabled])');

    interactiveElements.forEach(function(element) {
        $(element).on('mouseleave', function() {
            $cursor.removeClass('is-hover');
        });

        $(element).on('mouseenter', function() {
            $cursor.addClass('is-hover');
        });
    });

    $cursor.removeClass('is-hover');
    $cursor.removeClass('is-active')

}