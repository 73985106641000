"use strict";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-img-teaser-slider');


    import('slick-carousel').then(function () {
        $slider.slick({
            nextArrow: '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
            prevArrow: '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
            arrows: true,
            infinite: false,
            slidesToShow: 3.4,
            draggable: true ,
            swipeToSlide:true,
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 2.4
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1.4
                    }
                }
            ]
        });

        $slider.on('beforeChange', function(slick, currentSlide, nextSlide){
            $('body').addClass('lg-on');
        });

        $slider.on('afterChange', function(slick, currentSlide, nextSlide){
            $('body').removeClass('lg-on');
        });

    });

}
