"use strict";

export function initInScope($scope) {
    $(window).scroll(function () {
        //will trigger when your element comes into viewport
        let $containerClass = $('.js-autoplay-container');
        let hT = $containerClass.offset().top,
            hH = $containerClass.outerHeight(),
            wH = $(window).height(),
            wS = $(this).scrollTop();

        if (wS > (hT + hH - wH)) {
            //appends &autoplay=1 to iFrame src, making it autoplay
            let $iFrameElement = $('.js-autoplay-iframe iframe');
            $iFrameElement.attr('allow', 'autoplay')
            let $videoUrl = $iFrameElement.attr('src');
            if ($videoUrl.indexOf("&autoplay=1") < 0) {
                $iFrameElement.attr('src', $videoUrl + "&autoplay=1&muted=1");
            }
        }
    });
}