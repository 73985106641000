"use strict";

export function initInScope ($scope) {
    const startThreshold = window.innerHeight * ((isMobile) ? 0.25 : 0.35)  // the point where the navbar background starts to get visible

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    function handleScroll() {
        if(window.scrollY > startThreshold) {
            $scope.find('.navbar-main .background').each(function() {
                $(this).addClass('js-has-background');
            });
        } else {
            $scope.find('.navbar-main .background').each(function() {
                $(this).removeClass('js-has-background');
            });
        }
    }

    function isMobile() {
        const media = window.matchMedia("(max-width: 768px)");
        return media.matches
    }
}