import 'jquery-menu-aim';

export function init() {
    $('.js-nav__link-toggle').on('click', function (evt) {
        let $currentLink = $(this);
        let $nav = $currentLink.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');
        let $navItem = $currentLink.closest('.js-nav__item');

        evt.preventDefault();

        if($navItem.hasClass('is-open')){
            $navItem.removeClass('is-open');
            $nav.removeClass('is-open');
            $navItems.removeClass('is-open');
            $('body').off('click', bodyClickHandler);

        }else{
            $nav.addClass('is-open');
            $navItems.removeClass('is-open');
            $navItem.addClass('is-open');

            $('body').on('click', bodyClickHandler);

        }

    });

    $('.js-nav__subnav-toggle').on('click', function (evt) {
        let $currentLink = $(this);
        let $nav = $currentLink.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');
        let $navItem = $currentLink.closest('.js-nav__item');

        evt.preventDefault();

        if($navItem.hasClass('is-open')){
            $navItems.removeClass('is-open');

            $navItem.removeClass('is-open');

            $('body').off('click', bodyClickHandler);

        }else{
            $navItems.removeClass('is-open');

            $navItem.addClass('is-open');

            $('body').on('click', bodyClickHandler);

        }

    });

    $('.js-nav__back').on('click', function () {
        let $close = $(this);
        let $nav = $close.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');

        if (!matchMedia('(max-width: 767px)').matches) {
            $nav.removeClass('is-open');
        }

        $navItems.removeClass('is-open');

    });
    //
    // $('.js-nav__toggle').on('click', function () {
    //     let $toggler = $(this);
    //     let $nav = $toggler.closest('.js-nav');
    //     let $navItems = $nav.find('.js-nav__item');
    //
    //     $toggler.toggleClass('active');
    //     $nav.toggleClass('is-open');
    //     $navItems.removeClass('is-open');
    //
    //     if ($nav.hasClass('is-open')) {
    //         if (matchMedia('(max-width: 767px)').matches) {
    //             $('html').addClass('is-scrolling-disabled');
    //         }
    //     } else {
    //         if (matchMedia('(max-width: 767px)').matches) {
    //             $('html').removeClass('is-scrolling-disabled');
    //         }
    //     }
    // });


}

function bodyClickHandler (evt) {

    let $target = $(evt.target);
    let $parent = $target.closest('.js-nav__link-toggle, .subnav');

    if (!$target.is('.js-nav__link-toggle, .subnav') && !$parent.length) {
        evt.preventDefault();
        $('.js-nav__item').removeClass('is-open');
        $('.js-nav').removeClass('is-open');
        $('body').off('click', bodyClickHandler);
    }
}
