"use strict";

let currentId = 1;
export function initInScope ($scope) {
    let $videos = $scope.find('.js-video-js');
    let defaultOptions = {};
    let $btnToggleVolume = $scope.find('.js-toggle-volume');
    let $btnToggleVolumeIcon = $scope.find('.js-toggle-volume-icon');

    import('video.js').then(function (videojs){
        $videos.each(function () {
            let id = $(this).prop('id'),
                options = $.extend({}, defaultOptions);

            let autoStart = $(this).data('auto-start') || false;

            if (!id) {
                id = 'video-js-id-' + currentId++;
                $(this).prop('id', id);
            }

            let video = videojs.default(id, options);

            let $container = $(this).closest('.js-video-js-container');

            let muted = true;
            $btnToggleVolume.on('click', function () {
                if(video.getAttribute('id') === "video-js-id-1") {
                    if (muted) {
                        video.muted(false);
                        $btnToggleVolumeIcon.addClass('icon-volume-on');
                        $btnToggleVolumeIcon.removeClass('icon-volume-off');
                    } else {
                        video.muted(true);
                        $btnToggleVolumeIcon.addClass('icon-volume-off');
                        $btnToggleVolumeIcon.removeClass('icon-volume-on');
                    }
                    muted = !muted;
                }
            });


            video.on('playing', function () {
                $container.addClass('is-playing');
                $container.removeClass('is-ended');
            });

            video.on('pause', function () {
                $container.removeClass('is-playing');
            });

            video.on('ended', function () {
                $container.removeClass('is-playing');
                $container.addClass('is-ended');
            });

            video.on('ready', function () {
                if (autoStart) {
                    let t = setTimeout(function () {
                        video.play();

                        clearTimeout(t);
                    }, autoStart);
                }
            })
        });
    });
}