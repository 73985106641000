"use strict";

export function initInScope ($scope) {
    let svg = $scope.find('.js-circle-percent');

    let t = setTimeout(function () {
        svg.each(function () {
            let val = $(this).data('percent'),
                fillCircle = $(this).children('.js-circle-percent__percent');

            $(this).addClass('is-animated');
            fillCircle.css('stroke-dasharray', '' + val + ' 100');
        });
        clearTimeout(t);
    }, 450);
}