"use strict";

import fetch from '@elements/fetch';
import asyncAppend from "@elements/async-append";

export function initInScope($scope) {
    let $asyncRequest = $scope.find('.js-async-request');
    console.log($asyncRequest);

    $asyncRequest.on('click', () => {

        let url = $asyncRequest.attr('data-async-request-url');
        let $targetEl = $($asyncRequest.attr('data-target'));
        let $loadingEl = $($asyncRequest.attr('data-loading'));
        let $notificationsEl = $($asyncRequest.attr('data-notifications'));


        if(url === 'undefined' || url === null || url === '') {
            console.warn("async-request: data-async-request-url is not defined");
        }

        asyncAppend({
            $target: $targetEl,
            $loading: $loadingEl,
            $notifications: $notificationsEl
        }, fetch(url));
    });
}
