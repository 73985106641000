"use strict";

export function initInScope ($scope) {

    console.log("webtv-video");
    let $container = $scope.find('.js-webtv-video-container');

    let $video = $container.find('.js-webtv-video');

    console.log($video[0]);
    console.log("video[0]");

    $video.on('ready', function () {
        console.log("test");
    })
}
