let $mainNav = $('.js-nav-parent');
let $navToggle = $('.js-nav__toggle');

export function init() {


    $navToggle.on('click', function (e){

        if($mainNav.hasClass('is-open')){
            $mainNav.removeClass('is-open');
            $('body').off('click', bodyClickHandler);

        }else{
            $mainNav.addClass('is-open');
            $('body').on('click', bodyClickHandler);
        }

    });

}

function bodyClickHandler (evt) {

    let $target = $(evt.target);
    let $parent = $target.closest('.js-nav-parent');

    if (!$target.is('.js-nav-parent') && !$parent.length) {
        evt.preventDefault();
        $mainNav.removeClass('is-open');
        $('body').off('click', bodyClickHandler);
    }
}
