"use strict";
import {translate} from '@elements/translations';

export function init () {

    let $wyswygTexts = $('.js-expand-text');

    $wyswygTexts.each(function (){
        if($(this).height() > 180 && ($(window).width() < 768)){
            $(this).addClass("wysiwyg--text-expand");
            let $readMoreBtn= $('<button class="btn-no-styling text-primary js-wysiwyg-readmore-btn"></button>');
            $readMoreBtn.text(translate('WysiwygShowMoreBtn'));
            $readMoreBtn.insertAfter($(this));
        }
    });

    let $wysiwygReadMoreBtns = $(".js-wysiwyg-readmore-btn");

    $wysiwygReadMoreBtns.each(function (){
        $(this).on('click', function(){
            let $currentWysiwyg = $(this).parent().find(".wysiwyg");
            $currentWysiwyg.toggleClass("show-content");
            $currentWysiwyg.toggleClass("wysiwyg--text-expand");
            var replaceText =$currentWysiwyg.hasClass("show-content") ? translate('WysiwygShowLessBtn') : translate('WysiwygShowMoreBtn');
            $(this).text(replaceText);
        });
    });
}
