"use strict";

export function initInScope ($scope) {

    $scope.find('.js-facility-tooltip__trigger').each(function (e) {

        let $tooltipTrigger = $(this),
            $tooltipContainer = $tooltipTrigger.closest('.js-facility-tooltip'),
            $tooltipTemplate = $tooltipContainer.find('.js-facility-tooltip__template').prop('innerHTML');

        $tooltipTrigger.tooltip({
            boundary: 'window',
            placement:'top',
            offset: '0 25px',
            title: $tooltipTemplate,
            template: 	'<div class="tooltip tooltip--facility js-facility-tooltip__tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        });

    });

}