import $ from 'jquery';

import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

let $breadcrumb = $('.breadcrumb');
let left = $breadcrumb.outerWidth();
$breadcrumb.scrollLeft(left);

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as lazyIframe from '@elements/lazy-iframe';
app.modules.lazyIframe = lazyIframe.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as webtvVideo from './scripts/webtv-video';
app.modules.webtvVideo = webtvVideo.initInScope;

import * as customCursor from './scripts/custom-cursor';
app.modules.customCursor = customCursor.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as asyncRequest from './scripts/async-request';
app.modules.asyncRequest = asyncRequest.initInScope;

import * as nav from './scripts/nav';
app.nav = nav.init;

import * as navbar from './scripts/navbar';
app.navbar = navbar.init;

import * as stantonWidget from './scripts/stanton-widget';
app.stantonWidget = stantonWidget.initInScope;

import * as menuAim from './scripts/menu-aim';
app.modules.menuAim = menuAim.initInScope;

import * as funslope from './scripts/funslope';
app.modules.funslope = funslope.initInScope;

import * as slider from '@elements/slider';

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;


import * as affix from "@elements/affix";
affix.init({
    breakpoints: {
        768: {
            offset: 150
        },
        1200: {
            offset: 180
        }
    }
});




import * as imgTeaserSlider from './scripts/img-teaser-slider';
app.modules.imgTeaserSlider = imgTeaserSlider.initInScope;

// app.modules.imgTeaserSlider = slider.createInitInScope('.js-img-teaser-slider', {
//     nextArrow: '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
//     prevArrow: '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
//     arrows: false,
//     infinite: false,
//     slidesToShow: 3.4,
//     draggable: true,
//     slidesToScroll: 1,
//     responsive: [
//         {
//             breakpoint: 1300,
//             settings: {
//                 slidesToShow: 2.4
//             }
//         },
//         {
//             breakpoint: 767,
//             settings: {
//                 slidesToShow: 1.4
//             }
//         }
//     ]
// });

app.modules.snowreportSlider = slider.createInitInScope('.js-snowreport-slider', {
    nextArrow: '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
    prevArrow: '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1
});

/*
app.modules.socialMediaSlider = slider.createInitInScope('.js-social-media-slider', {
    nextArrow: '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
    prevArrow: '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1
});
*/
app.modules.contentTeaserSlider = slider.createInitInScope('.js-content-teaser-slider', {
    nextArrow: '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
    prevArrow: '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    draggable: true,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.1
            }
        }
    ]
});

import * as mediaGallerySlider from './scripts/media-gallery-slider';
app.modules.mediaGallerySlider = mediaGallerySlider.initInScope;

import * as anchorNav from './scripts/anchor-nav';
app.anchorNav = anchorNav.init;

import * as smoothScroll from './scripts/smooth-scroll';
app.modules.smoothScroll = smoothScroll.initInScope;

import * as facilityTooltip from './scripts/facility-tooltip';
app.modules.facilityTooltip = facilityTooltip.initInScope;

import * as warningModal from './scripts/warning-modal';
app.modules.warningModal = warningModal.initInScope;

import * as percentageCircle from './scripts/percentage-circle';
app.modules.percentageCircle = percentageCircle.initInScope;

import * as panomax from './scripts/panomax';
app.modules.panomax = panomax.initInScope;

import * as ajaxForm from '@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.initInScope;

import * as ajaxFormExtend from './scripts/ajax-form-extend'
app.modules.ajaxFormExtend = ajaxFormExtend.initInScope;

import * as datepicker from '@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as popover from './scripts/popover';
app.modules.popover = popover.initInScope;

import * as appBanner from './scripts/app-banner';
app.modules.appBanner = appBanner.init;

import * as datepickerRange from '@elements/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as dragIndicator from './scripts/drag-indicator';
app.modules.dragIndicator = dragIndicator.initInScope;

import * as warningDisrupter from './scripts/warning-disrupter';
app.modules.warningDisrupter = warningDisrupter.initInScope;

import * as trackAssetVideo from './scripts/track-asset-video';
app.modules.trackAssetVideo = trackAssetVideo.initInScope;

import * as expandText from './scripts/expand-text';
app.modules.expandText = expandText.init;

import * as youtubeAutoplay from './scripts/youtube-autoplay';
app.modules.youtubeAutoplay = youtubeAutoplay.initInScope;

import * as newsletterForm from './scripts/newsletter-form';
app.modules.newsletterForm = newsletterForm.initInScope;

import * as closeNewsletterBanner from './scripts/newsletter-banner-close';
app.modules.closeNewsletterBanner = closeNewsletterBanner.initInScope;

import * as closeNoticeBanner from './scripts/notice-banner-close';
app.modules.closeNoticeBanner = closeNoticeBanner.initInScope;

import * as noMoreTables from './scripts/no-more-tables';
app.modules.noMoreTables = noMoreTables.initInScope;

import * as cookieinformationPlaceholder from './scripts/cookieinformation-placeholder';
app.modules.cookieinformationPlaceholder = cookieinformationPlaceholder.initInScope;

import * as openCookieSettings from './scripts/open-cookie-settings';
app.modules.openCookieSettings = openCookieSettings.initInScope;

import * as opaqueNavbar from './scripts/opaque-navbar'
app.modules.opaqueNavbar = opaqueNavbar.initInScope;

import * as newsletterModal from './scripts/newsletter-modal';
app.modules.newsletterModal = newsletterModal.initInScope;

import * as newsletterPopup from './scripts/newsletter-popup'
app.modules.newsletterPopup = newsletterPopup.initInScope;

import * as navbarBrandScroll from './scripts/navbar-brand-scroll';
app.modules.navbarBrandScroll = navbarBrandScroll.initInScope;

/*
import { showCookieBar } from "@elements/cookie-permissions";
if (!_config.admin && !_config.editmode) {
    showCookieBar();
}
*/

import * as touchNav from '@elements/touch-nav';
app.modules.touchNav = touchNav.createInitInScope({
    base: '.js-nav',
    item: '.js-nav__item'

});

import {translate} from '@elements/translations';

import * as quickfinder from '@elements/quickfinder';
app.modules.quickfinder = quickfinder.createInitInScope('.js-quickfinder', {},
    renderChildAgeInput
);


function renderChildAgeInput({index, value, onChange}) {
    let markupElement = document.createElement('div');

    let markup = `<div class="col-md-3 col-6">` +
        `<div class="select form-group">` +
        `<div class="select__input-wrapper position-relative">` +
        `<label class="form-control-label font-default floating-label" for="childAge${index}"> ${translate('form.childAge')} ${index}</label>` +
        `<select name="ca0[]" id="childAge${index}" name="childAge${index}" class="form-control js-floating-label" required>` +
        `<option class="d-none" value="" ${ value ? '': 'selected'} ></option>`;

    for (let i = 0; i < 18; i++) {
        markup += `<option value="${i}" ${i == value ? 'selected': ''}>${i} ${translate('form.Jahre')}</option>`;
    }

    markup += `</select>` +
        `</div>`+
        `<div class="form-errors invalid-feedback"></div>` +
        `</div></div>`;

    markupElement.innerHTML = markup;
    let quickfinderItem = markupElement.childNodes[0];    //remove wrapper-div

    quickfinderItem.addEventListener('change', function(e) {
        onChange(e.target.value);
    });

    return quickfinderItem;
}

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);