"use strict";

export function initInScope($scope) {
    $scope.find('.js-ajax-form').on('fetch.ajax-form', function () {
        if($(window).scrollTop() > $(this).offset().top) {
            $('html, body').animate({
                scrollTop: $(this).offset().top - 20
            }, 500);
        }
    });
}