"use strict";

export function init () {
    import('../libs/smart-app-banner.js').then(function () {
        let iconPath = '';
        if (_config.appBannerText.icon){
            iconPath = _config.appBannerText.icon
        }

        new SmartBanner({
            daysHidden: 365,   // days to hide banner after close button is clicked (defaults to 15)
            daysReminder: 365, // days to hide banner after "VIEW" button is clicked (defaults to 90)
            appStoreLanguage: _config.lang, // language code for the App Store (defaults to user's browser language)
            title: _config.appBannerText.title,
            author: _config.appBannerText.subtitle,
            button: _config.appBannerText.cta,
            icon: iconPath
        });
    });

}

