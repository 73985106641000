"use strict";

const selectors = {
    newsletterModal: '.js-newsletter-modal',
    bootstrapModal: '.js-modal'
}

export function initInScope ($scope) {
    $scope.find(selectors.newsletterModal).each(function(index) {
        // check if the div with class modal inside the newsletterModal element has a certain class
        if($(this).find(selectors.bootstrapModal).hasClass('js-newsletter-modal-form__is-initially-open')) {
            $(selectors.bootstrapModal).modal({
                show: true
            })
        }
    })
}
