"use strict";

export function initInScope($scope) {
    var $baseElement = $scope.find('.js-cookieinformation-placeholder');

    $(window).on('CookieInformationConsentGiven', function () {
        if ($baseElement) {
            if(!CookieInformation.getConsentGivenFor($baseElement.attr('data-category'))){
                $baseElement.show();
            }else{
                $baseElement.hide();
            }
        }
    });
    // onFind(selectors.base, function (baseElement) {
    //     window.addEventListener('CookieInformationConsentGiven', function(event) {
    //         if(baseElement){
    //             if(!CookieInformation.getConsentGivenFor(baseElement.getAttribute('data-category'))){
    //                 baseElement.style.display = 'block';
    //             }else{
    //                 baseElement.style.display = 'none';
    //             }
    //         }
    //     }, false);
    // },document);
}

