"use strict";

export function initInScope ($scope) {

    let $button = $('.js-warning-disrupter__btn');
    let $moreInfoBtn = $('.js-warning-disrupter__more-info');
    let $warning = $('.js-warning-disrupter');
    let $icon = $('.js-warning-disrupter__icon');


    if(localStorage.getItem('disrupterClosedOnClick')){
        setTimeout(function() {
            $warning.addClass('has-transition');
            $warning.removeClass('is-open');
            $icon.removeClass('icon-arrow-right');
            $icon.addClass('icon-snow');
        }, 100);
        $warning.removeClass('has-transition');
        localStorage.removeItem('disrupterClosedOnClick');
    }

    if(!localStorage.getItem('disrupterClosed')) {
        if(!$warning.hasClass('is-open')) {
            console.log("open");
            setTimeout(function() {
                $warning.addClass('has-transition');
                $warning.addClass('is-open');
                $icon.removeClass('icon-snow');
                $icon.addClass('icon-arrow-right');
            }, 4000); // increased timeout for better CLS score
            $warning.removeClass('has-transition');
            localStorage.removeItem('disrupterClosed');
        }
    }

    $button.on('click', function(evt) {
        if($warning.hasClass('is-open')){
            setTimeout(function() {
                $warning.addClass('has-transition');
                $warning.removeClass('is-open');
                $icon.removeClass('icon-arrow-right');
                $icon.addClass('icon-snow');
            }, 100);
            $warning.removeClass('has-transition');
            localStorage.setItem('disrupterClosed', 'true');

            
            /*$('body').off('click', bodyClickHandler);*/
        } else {
            setTimeout(function() {
                $warning.addClass('has-transition');
                $warning.addClass('is-open');
                $icon.removeClass('icon-snow');
                $icon.addClass('icon-arrow-right');
            }, 100);
            $warning.removeClass('has-transition');
            localStorage.removeItem('disrupterClosed');
            /*$('body').on('click', bodyClickHandler);*/
        }
    });

    $moreInfoBtn.on('click', function (e) {
        localStorage.setItem('disrupterClosed', 'true');
        localStorage.setItem('disrupterClosedOnClick', 'true');
    });

}

function bodyClickHandler(evt, $button) {

    let $target = $(evt.target);
    let $warning = $('.js-warning-disrupter');

    if($target !== $button) {
        evt.preventDefault();
        $warning.removeClass('is-open');
        $('body').off('click', bodyClickHandler);
    }
}