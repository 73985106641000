"use strict";
import loadScript from '@elements/load-script';

export function initInScope($scope) {
    let $panomax = $scope.find('.js-panomax-tab');

    $panomax.each(function () {
        $(this).on('shown.bs.tab', function () {
            PmaxThumbnailPmax.manager.onResize();
        });
    });
}