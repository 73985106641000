"use strict";
import loadScript from '@elements/load-script';

export function initInScope($scope) {


    $(document).ready(function()
    {
        loadScript("https://api.qparks.com/action/minify/cust/cms/file/widget-js/widget/stanton-snowpark/").then(() => {
            let setupWidget = new ymWidgets();
            let path = "/setup/stanton/";
            if(_config.lang === 'en'){
                path = "/setup/stanton-en/";
            }
            setupWidget.start(
                $('body').find("[data-ym=widget]"),
                path
            );
        })
    });

}