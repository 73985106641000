"use strict";

export function initInScope ($scope) {
    let $table = $scope.find('.js-no-more-tables');

    $table.each(function() {
        let dataTitles = [];
        let ths = $(this).find("table thead tr th");
        let trs = $(this).find("table tbody tr");

        ths.each(function() {
           dataTitles.push($(this).html());
        });

        trs.each(function() {
           let tds = $(this).find("td");
           if (tds.length === 1) {
               $(this).find("td").addClass("no-more-tables--flex-col");
           }
            tds.each(function(index) {
                $(this).wrapInner("<div></div>");
                $(this).prepend("<div class='no-more-tables__title'>" + dataTitles[index] + "</div>");
            });
        });
    });
}