"use strict";

export function init () {

    //init scrollspy
    $('body').scrollspy({ target: '#anchorNav', offset: 200 });

    //add nav items
    function addNavItem (text, anchorId) {
        $('.js-anchor-nav__list').append('<li class="anchor-nav__item nav-item"><a class="js-smooth-scroll anchor-nav__item__link nav-link" href="#' + anchorId + '"><span class="anchor-nav__item__link__title">' + text + '</span></a></li>');
    }
    
    $('.js-anchor-nav__item').each(function (index, item) {
        let $this = $(this),
            title = String($this.data('nav-name'));
        let titleForAnchor = title.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '').toLowerCase();

        if($this.attr('id')){
            addNavItem(title,$this.attr('id'));
        }else{
            $this.attr('id', titleForAnchor);
            addNavItem(title,titleForAnchor);
        }

        $('body').scrollspy('refresh');
    });

    $(document).ready(function(){
        scrollToAnchor();
    });
}

function scrollToAnchor() {
    let hash = window.location.hash;

    let $anchorElement = $(hash);
    if($anchorElement.length){
        $('html, body').animate({
            scrollTop: $anchorElement.offset().top - 100
        }, 500);
    }
}